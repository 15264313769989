import { Injectable } from '@angular/core';
import { CoreFilepool } from '@services/filepool';
import {CoreSites, CoreSitesCommonWSOptions} from '@services/sites';
import { CoreUtils } from '@services/utils/utils';
import { makeSingleton } from '@singletons';
import {CoreWSExternalWarning} from "@services/ws";
import {CoreSite, CoreSiteWSPreSets} from "@classes/site";
import {CoreError} from "@classes/errors/error";

const ROOT_CACHE_KEY = 'mmaModCourseref:';

/**
 * Service that provides some features for courserefs.
 */
@Injectable({ providedIn: 'root' })
export class AddonModCourserefProvider {

    static readonly COMPONENT = 'mmaModCourseref';

    /**
     * Get cache key for courseref data WS calls.
     *
     * @param courseId Course ID.
     * @return Cache key.
     */
    protected getCourserefDataCacheKey(courseId: number): string {
        return ROOT_CACHE_KEY + 'courseref:' + courseId;
    }

    /**
     * Invalidate courseref data.
     *
     * @param courseId Course ID.
     * @param siteId Site ID. If not defined, current site.
     * @return Promise resolved when the data is invalidated.
     */
    async invalidateCourserefData(courseId: number, siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);
        await site.invalidateWsCacheForKey(this.getCourserefDataCacheKey(courseId));
    }

    /**
     * Invalidate the prefetched content.
     *
     * @param moduleId The module ID.
     * @param courseId Course ID.
     * @param siteId Site ID. If not defined, current site.
     * @return Promise resolved when data is invalidated.
     */
    async invalidateContent(moduleId: number, courseId: number, siteId?: string): Promise<void> {
        siteId = siteId || CoreSites.getCurrentSiteId();

        const promises: Promise<void>[] = [];

        promises.push(this.invalidateCourserefData(courseId, siteId));
        promises.push(CoreFilepool.invalidateFilesByComponent(siteId, AddonModCourserefProvider.COMPONENT, moduleId, true));

        await CoreUtils.allPromises(promises);
    }

    isGetCourserefWSAvailable(): boolean {
        return CoreSites.wsAvailableInCurrentSite('mod_courseref_get_courserefs_by_courses');
    }

    /**
     * Get a courseref by course module ID.
     *
     * @param courseId Course ID.
     * @param courserefId Courseref ID.
     * @param options Other options.
     * @return Promise resolved when the courseref is retrieved.
     */
    getCourseref(courseId: number, courserefId: number, options: CoreSitesCommonWSOptions = {}): Promise<AddonModCourserefCourseref> {
        return this.getCourserefByField(courseId, 'id', courserefId, options);
    }

    /**
     * Get a courseref with key=value. If more than one is found, only the first will be returned.
     *
     * @param courseId Course ID.
     * @param key Name of the property to check.
     * @param value Value to search.
     * @param options Other options.
     * @return Promise resolved when the courseref is retrieved.
     */
    protected async getCourserefByField(
        courseId: number,
        key: string,
        value: number,
        options: CoreSitesCommonWSOptions = {},
    ): Promise<AddonModCourserefCourseref> {
        const site = await CoreSites.getSite(options.siteId);

        const params: AddonModCourserefGetCourserefsByCoursesWSParams = {
            courseids: [courseId],
        };

        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getCourserefDataCacheKey(courseId),
            updateFrequency: CoreSite.FREQUENCY_RARELY,
            component: AddonModCourserefProvider.COMPONENT,
            ...CoreSites.getReadingStrategyPreSets(options.readingStrategy),
        };

        const response =
            await site.read<AddonModCourserefGetCourserefsByCoursesWSResponse>('mod_courseref_get_courserefs_by_courses', params, preSets);

        const currentCourseRef = response.courserefs.find((courseRef) => courseRef[key] == value);
        if (currentCourseRef) {
            return currentCourseRef;
        }

        throw new CoreError('Courseref not found');
    }
}
export const AddonModCourseref = makeSingleton(AddonModCourserefProvider);

/**
 * Courseref returned by mod_courseref_get_courserefs_by_courses.
 */
export type AddonModCourserefCourseref = {
    id: number;
    course: number;
    name: string;
    courseref: number;
    intro: string;
    introformat?: number;
    timemodified: number;
    dashboard_visible: boolean;
};

/**
 * Params of mod_courseref_get_courserefs_by_courses WS.
 */
type AddonModCourserefGetCourserefsByCoursesWSParams = {
    courseids?: number[]; // Array of course ids.
};

/**
 * Data returned by mod_courseref_get_courserefs_by_courses WS.
 */
type AddonModCourserefGetCourserefsByCoursesWSResponse = {
    courserefs: AddonModCourserefCourseref[];
    warnings?: CoreWSExternalWarning[];
};

