import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CoreContentLinksDelegate } from '@features/contentlinks/services/contentlinks-delegate';
import { CoreCourseModuleDelegate } from '@features/course/services/module-delegate';
import { AddonModCourserefIndexLinkHandler } from './services/handlers/index-link';
import { AddonModCourserefModuleHandler } from './services/handlers/module';
import {CoreCourseModulePrefetchDelegate} from "@features/course/services/module-prefetch-delegate";
import {AddonModCourserefPrefetchHandler} from "@addons/mod/courseref/services/handlers/prefetch";

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [],
            useFactory: () => () => {
                CoreCourseModuleDelegate.registerHandler(AddonModCourserefModuleHandler.instance);
                CoreContentLinksDelegate.registerHandler(AddonModCourserefIndexLinkHandler.instance);
                CoreCourseModulePrefetchDelegate.registerHandler(AddonModCourserefPrefetchHandler.instance);
            },
        },
    ],
})
export class AddonModCourseRefModule {}
