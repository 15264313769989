import { Component, ViewEncapsulation } from '@angular/core';
import {CoreComboboxComponent} from "@components/combobox/combobox";


@Component({
    selector: 'section-selector-combobox',
    templateUrl: 'section-selector-combobox.html',
    styleUrls: ['combobox.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class CoreCourseSectionSelectorComboboxComponent extends CoreComboboxComponent {

}
