import { Injectable } from '@angular/core';
import { CoreCourseResourcePrefetchHandlerBase } from '@features/course/classes/resource-prefetch-handler';
import { CoreCourse, CoreCourseAnyModuleData } from '@features/course/services/course';
import { CoreSitesReadingStrategy } from '@services/sites';
import { CoreUtils } from '@services/utils/utils';
import { CoreWSFile } from '@services/ws';
import { makeSingleton } from '@singletons';
import {
    AddonModCourseref,
    AddonModCourserefCourseref,
    AddonModCourserefProvider
} from "@addons/mod/courseref/services/courseref";

/**
 * Handler to prefetch courserefs.
 */
@Injectable({ providedIn: 'root' })
export class AddonModCourserefPrefetchHandlerService extends CoreCourseResourcePrefetchHandlerBase {

    name = 'AddonModCourseref';
    modName = 'courseref';
    component = AddonModCourserefProvider.COMPONENT;
    updatesNames = /^.*files$/;
    skipListStatus = true;

    /**
     * @inheritdoc
     */
    async getIntroFiles(module: CoreCourseAnyModuleData, courseId: number, ignoreCache?: boolean): Promise<CoreWSFile[]> {
        let courseRef: AddonModCourserefCourseref | undefined;

        if (AddonModCourseref.isGetCourserefWSAvailable()) {
            courseRef = await AddonModCourseref.getCourseref(courseId, module.instance!, {
                readingStrategy: ignoreCache ? CoreSitesReadingStrategy.ONLY_NETWORK : undefined,
            });
        }

        return this.getIntroFilesFromInstance(module, courseRef);
    }

    /**
     * @inheritdoc
     */
    async invalidateContent(moduleId: number, courseId: number): Promise<void> {
        await AddonModCourseref.invalidateContent(moduleId, courseId);
    }

    /**
     * @inheritdoc
     */
    async invalidateModule(module: CoreCourseAnyModuleData, courseId: number): Promise<void> {
        const promises: Promise<void>[] = [];

        promises.push(AddonModCourseref.invalidateCourserefData(courseId));
        promises.push(CoreCourse.invalidateModule(module.id));

        await CoreUtils.allPromises(promises);
    }

}
export const AddonModCourserefPrefetchHandler = makeSingleton(AddonModCourserefPrefetchHandlerService);
