// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreCourseActivityPrefetchHandlerBase } from '@features/course/classes/activity-prefetch-handler';
import { CoreCourseAnyModuleData } from '@features/course/services/course';
import { CoreH5PHelper } from '@features/h5p/classes/helper';
import { CoreH5P } from '@features/h5p/services/h5p';
import { CoreUser } from '@features/user/services/user';
import { CoreFilepool } from '@services/filepool';
import { CoreSites, CoreSitesReadingStrategy } from '@services/sites';
import { CoreWSFile } from '@services/ws';
import { makeSingleton } from '@singletons';
import { AddonModHVP, AddonModHVPData, AddonModHVPProvider } from '../hvp';

/**
 * Handler to prefetch h5p activity.
 */
@Injectable({ providedIn: 'root' })
export class AddonModHVPPrefetchHandlerService extends CoreCourseActivityPrefetchHandlerBase {

    name = 'AddonModHVP';
    modName = 'hvp';
    component = AddonModHVPProvider.COMPONENT;
    updatesNames = /^configuration$|^.*files$|^tracks$|^usertracks$/;

    /**
     * @inheritdoc
     */
    async getFiles(module: CoreCourseAnyModuleData, courseId: number): Promise<CoreWSFile[]> {

        const hvp = await AddonModHVP.getHVP(courseId, module.id);

        const displayOptions = CoreH5PHelper.decodeDisplayOptions(hvp.displayoptions);

        const deployedFile = await AddonModHVP.getDeployedFile(hvp, {
            displayOptions,
        });

        return [deployedFile].concat(this.getIntroFilesFromInstance(module, hvp));
    }

    /**
     * @inheritdoc
     */
    async invalidateModule(): Promise<void> {
        // No need to invalidate anything.
    }

    /**
     * @inheritdoc
     */
    async isDownloadable(): Promise<boolean> {
        return !!CoreSites.getCurrentSite()?.canDownloadFiles() && !CoreH5P.isOfflineDisabledInSite();
    }

    /**
     * @inheritdoc
     */
    isEnabled(): Promise<boolean> {
        return AddonModHVP.isPluginEnabled();
    }

    /**
     * @inheritdoc
     */
    prefetch(module: CoreCourseAnyModuleData, courseId: number): Promise<void> {
        return this.prefetchPackage(module, courseId, this.prefetchActivity.bind(this, module, courseId));
    }

    /**
     * Prefetch an H5P activity.
     *
     * @param module Module.
     * @param courseId Course ID the module belongs to.
     * @param siteId Site ID. If not defined, current site.
     * @return Promise resolved when done.
     */
    protected async prefetchActivity(
        module: CoreCourseAnyModuleData,
        courseId: number,
        siteId: string,
    ): Promise<void> {
        const hvp = await AddonModHVP.getHVP(courseId, module.id, {
            readingStrategy: CoreSitesReadingStrategy.ONLY_NETWORK,
            siteId,
        });

        const introFiles = this.getIntroFilesFromInstance(module, hvp);

        await Promise.all([
            this.prefetchWSData(hvp, siteId),
            CoreFilepool.addFilesToQueue(siteId, introFiles, AddonModHVPProvider.COMPONENT, module.id),
            this.prefetchMainFile(module, hvp, siteId),
        ]);
    }

    /**
     * Prefetch the deployed file of the activity.
     *
     * @param module Module.
     * @param hvp Activity instance.
     * @param siteId Site ID.
     * @return Promise resolved when done.
     */
    protected async prefetchMainFile(
        module: CoreCourseAnyModuleData,
        hvp: AddonModHVPData,
        siteId: string,
    ): Promise<void> {

        const displayOptions = CoreH5PHelper.decodeDisplayOptions(hvp.displayoptions);

        const deployedFile = await AddonModHVP.getDeployedFile(hvp, {
            displayOptions: displayOptions,
            ignoreCache: true,
            siteId: siteId,
        });

        await CoreFilepool.addFilesToQueue(siteId, [deployedFile], AddonModHVPProvider.COMPONENT, module.id);
    }

    /**
     * Prefetch all the WebService data.
     *
     * @param hvp Activity instance.
     * @param siteId Site ID.
     * @return Promise resolved when done.
     */
    protected async prefetchWSData(hvp: AddonModHVPData, siteId: string): Promise<void> {

        const accessInfo = await AddonModHVP.getAccessInformation(hvp.id, {
            cmId: hvp.coursemodule,
            readingStrategy: CoreSitesReadingStrategy.PREFER_CACHE,
            siteId,
        });

        const options = {
            cmId: hvp.coursemodule,
            readingStrategy: CoreSitesReadingStrategy.ONLY_NETWORK,
            siteId: siteId,
        };

        if (!accessInfo.canreviewattempts) {
            // Not a teacher, prefetch user attempts and the current user profile.
            const site = await CoreSites.getSite(siteId);

            await Promise.all([
                AddonModHVP.getAllAttemptsResults(hvp.id, options),
                CoreUser.prefetchProfiles([site.getUserId()], hvp.course, siteId),
            ]);
        } else {
            // It's a teacher, get all attempts if possible.
            const canGetUsers = await AddonModHVP.canGetUsersAttempts(siteId);
            if (!canGetUsers) {
                return;
            }

            const users = await AddonModHVP.getAllUsersAttempts(hvp.id, options);

            const userIds = users.map(user => user.userid);
            await CoreUser.prefetchProfiles(userIds, hvp.course, siteId);
        }
    }

}

export const AddonModHVPPrefetchHandler = makeSingleton(AddonModHVPPrefetchHandlerService);
