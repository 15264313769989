// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreContentLinksModuleIndexHandler } from '@features/contentlinks/classes/module-index-handler';
import { makeSingleton } from '@singletons';
import { AddonModFeedback } from '../feedback';

/**
 * Handler to treat links to feedback.
 */
@Injectable({ providedIn: 'root' })
export class AddonModFeedbackIndexLinkHandlerService extends CoreContentLinksModuleIndexHandler {

    name = 'AddonModFeedbackLinkHandler';

    constructor() {
        super('AddonModFeedback', 'feedback');
    }

    /**
     * Check if the handler is enabled for a certain site (site + user) and a URL.
     *
     * @param siteId The site ID.
     * @param url The URL to treat.
     * @param params The params of the URL. E.g. 'mysite.com?id=1' -> {id: 1}
     * @param courseId Course ID related to the URL. Optional but recommended.
     * @return Whether the handler is enabled for the URL and site.
     */
    isEnabled(): Promise<boolean> {
        return AddonModFeedback.isPluginEnabled();
    }

}

export const AddonModFeedbackIndexLinkHandler = makeSingleton(AddonModFeedbackIndexLinkHandlerService);
