import { Injectable } from '@angular/core';
import { CoreContentLinksModuleIndexHandler } from '@features/contentlinks/classes/module-index-handler';
import { makeSingleton } from '@singletons';

/**
 * Handler to treat links to courseref.
 */
@Injectable({ providedIn: 'root' })
export class AddonModCourserefIndexLinkHandlerService extends CoreContentLinksModuleIndexHandler {

    name = 'AddonModCourserefLinkHandler';

    constructor() {
        super('AddonModCourseref', 'courseref', 'l');
    }

}
export const AddonModCourserefIndexLinkHandler = makeSingleton(AddonModCourserefIndexLinkHandlerService);
