import { CoreConstants } from '@/core/constants';
import { Injectable } from '@angular/core';
import {CoreCourse, CoreCourseWSModule} from '@features/course/services/course';
import { CoreCourseModuleHandler, CoreCourseModuleHandlerData } from '@features/course/services/module-delegate';
import { makeSingleton } from '@singletons';
import {CoreCourseHelper, CoreCourseModule} from "@features/course/services/course-helper";
import {CoreNavigationOptions, CoreNavigator} from "@services/navigator";
import {CoreCourses} from "@features/courses/services/courses";
import {AddonModCourserefProvider} from "@addons/mod/courseref/services/courseref";

/**
 * Handler to support courseref modules.
 */
@Injectable({ providedIn: 'root' })
export class AddonModCourserefModuleHandlerService implements CoreCourseModuleHandler {

    constructor(private courserefProvider: AddonModCourserefProvider) {
    }

    name = 'AddonModCourseref';
    modName = 'courseref';

    supportedFeatures = {
        [CoreConstants.FEATURE_MOD_ARCHETYPE]: CoreConstants.MOD_ARCHETYPE_RESOURCE,
        [CoreConstants.FEATURE_IDNUMBER]: true,
        [CoreConstants.FEATURE_GROUPS]: false,
        [CoreConstants.FEATURE_GROUPINGS]: false,
        [CoreConstants.FEATURE_MOD_INTRO]: true,
        [CoreConstants.FEATURE_COMPLETION_TRACKS_VIEWS]: false,
        [CoreConstants.FEATURE_GRADE_HAS_GRADE]: false,
        [CoreConstants.FEATURE_GRADE_OUTCOMES]: false,
        [CoreConstants.FEATURE_BACKUP_MOODLE2]: true,
        [CoreConstants.FEATURE_SHOW_DESCRIPTION]: true,
    };

    /**
     * @inheritdoc
     */
    async isEnabled(): Promise<boolean> {
        return true;
    }

    /**
     * @inheritdoc
     */
    getData(module: CoreCourseWSModule): CoreCourseModuleHandlerData {
        return {
            icon: module.modicon,
            title: module.name,
            a11yTitle: '',
            class: 'addon-mod-courseref-handler',
            action: async (event: Event, module: CoreCourseModule, courseId: number, options?: CoreNavigationOptions) => {
                const courseRef = await this.courserefProvider.getCourseref(courseId, module.instance!)
                try {
                    const course = await CoreCourses.getUserCourse(courseRef.courseref);
                    await CoreCourseHelper.openCourse(course);
                }
                catch (e) {
                    await CoreCourses.dcaEnrol(courseRef.courseref, undefined, undefined, true);

                    // Invalidate the context - otherwise subsequent `getUserCourse` would not work
                    await CoreCourses.invalidateUserCourses();
                    await CoreCourses.invalidateCourse(courseRef.courseref);

                    const course = await CoreCourses.getUserCourse(courseRef.courseref);
                    await CoreCourseHelper.openCourse(course);
                }
            },
        };
    }

    /**
     * @inheritdoc
     */
    async getMainComponent(): Promise<undefined> {
        // There's no need to implement this because courseref cannot be used in singleactivity course format.
        return;
    }

    /**
     * @inheritdoc
     */
    async manualCompletionAlwaysShown(): Promise<boolean> {
        return true;
    }

}
export const AddonModCourserefModuleHandler = makeSingleton(AddonModCourserefModuleHandlerService);
